import request from "../../../../core/data/network/client/request";
import Api from "../../../../core/data/network/constants/api_routes";

export default {
  getHabeasData: async () => {
    return request.get(Api.getHabeasData());
  },
  getHabeasDataBySearch: async (term) => {
    return request.get(Api.getHabeasDataBySearching(term));
  },
  getHabeasDataBySearching: async (term) => {
    return request.get(Api.getHabeasDataBySearching(term));
  },
  // getReport: async (document_id) => {
  //   return request.get(Api.getReport(document_id));
  // },
  // manageReport: async (document_id, data) => {
  //   return request.post(Api.manageReport(document_id), data);
  // },
  // updateReport: async (document_id, data) => {
  //   return request.patch(Api.updateReport(document_id), data);
  // },
};