import ClassificationServicesImpl from "./derechos_peticion_services_impl";

export default {
  getClassifition: async () => {
    return ClassificationServicesImpl.getClassifition();
  },
  getClassifitionBySearch: async (data) => {
    return ClassificationServicesImpl.getClassifitionBySearch(data);
  },
  getReportClassifition: async (data) => {
    return ClassificationServicesImpl.getReportClassifition(data);
  },
  // manageReport: async (document_id, data) => {
  //   return AnnualReportServicesImpl.manageReport(document_id, data);
  // },
  // updateReport: async (document_id, data) => {
  //   return AnnualReportServicesImpl.updateReport(document_id, data);
  // },
};
