<template>
  <div>
    <a-table
      class="table-component"
      :columns="columns"
      :data-source="data"
      :scroll="{ x: '1220px' }"
    >
      <span slot="fecha_reporte" slot-scope="text, record">{{
        record.fecha_reporte
      }}</span>
      <span slot="responsable" slot-scope="text, record">{{
        record.responsable
      }}</span>
      <span
        :class="
          record.estado_ev == 'Borrador'
            ? 'text-blue-600 bg-blue-200'
            : 'text-green-600 bg-green-100'
        "
        slot="estado_ev"
        slot-scope="text, record"
      >
        {{ record.estado_ev }}
      </span>
      <div
        class="flex justify-evenly align-center"
        slot="action"
        slot-scope="text, record"
      >
        <button
          :disabled="record.estado_ev == 'Gestionado' ? true : false"
          :class="
            record.estado_ev !== 'Gestionado'
              ? 'bg-blue-900 py-2 px-5 rounded-lg text-white text-sm'
              : 'bg-gray-300 py-2 px-5 rounded-lg text-white text-sm'
          "
          @click="goToReportCreation(record.document_id)"
        >
          Gestionar
        </button>
        <button
          class="text-sm text-blue h-min"
          @click="goToReportDetail(record.document_id)"
        >
          <a-icon
            type="eye"
            theme="twoTone"
            two-tone-color="#3366cc"
            :style="{ fontSize: '22px' }"
          />
        </button>
        <button
          class="text-sm text-blue h-min"
          @click="goToReportEdit(record.document_id)"
        >
          <a-icon
            type="edit"
            theme="twoTone"
            two-tone-color="#3366cc"
            :style="{ fontSize: '22px' }"
          />
        </button>
      </div>
    </a-table>
  </div>
</template>

<script>
export default {
  props: [
    "columns",
    "data",
    "goToReportDetail",
    "goToReportCreation",
    "goToReportEdit",
  ],
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
button:disabled {
  /* background: gray; */
  cursor: not-allowed;
}

.disabled-icon {
  background: gray;
  cursor: not-allowed;
}
</style>