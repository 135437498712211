import request from "../../../../core/data/network/client/request";
import Api from "../../../../core/data/network/constants/api_routes";

export default {
  getClassifition: async () => {
    return request.get(Api.getClassifition());
  },
  getClassifitionBySearch: async (data) => {
    return request.get(Api.getClassifitionBySearching(data));
  },
  getReportClassifition: async (data) => {
    return request.get(Api.getReportClassition(data));
  },
  // getReport: async (document_id) => {
  //   return request.get(Api.getReport(document_id));
  // },
  // manageReport: async (document_id, data) => {
  //   return request.post(Api.manageReport(document_id), data);
  // },
  // updateReport: async (document_id, data) => {
  //   return request.patch(Api.updateReport(document_id), data);
  // },
};
